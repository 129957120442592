@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

/* .theme-dark .special-label {
  background-color: #24262d !important;
} */

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-24px);
    /* Adjust the height as needed */
  }
}

.bounce {
  animation: bounce 1.5s infinite;
}

.app {
  height: 100vh;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

/* .cursor-circle {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid rgb(109, 106, 106);
  pointer-events: none;
  transition: transform 0.1s ease-out, opacity 0.1s ease-out;
  transform: translate(-50%, -50%);
  z-index: 1000;
} */

.cursor-circle {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid rgb(109, 106, 106);
  pointer-events: none;
  transition: transform 0.1s ease-out, opacity 0.1s ease-out;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner-dot {
  width: 5px;
  /* Adjust size as needed */
  height: 5px;
  /* Adjust size as needed */
  border-radius: 50%;
  background-color: rgb(109, 106, 106);
}

h1 {
  color: #333;
  font-family: Arial, sans-serif;
}

h1:hover + .cursor-circle {
  transform: translate(-50%, -50%) scale(2);
  background-color: rgba(0, 255, 0, 0.5);
}

.onhover:hover {
  color: #808285;
}

.event-titleee {
  color: #223645;
  font-size: 21px !important;
  font-family: "Poppins", sans-serif !important;
}

.event-titleee:hover {
  color: #7e3af2;
}

.date-widthhh {
  width: fit-content !important;
}

@media only screen and (max-width: 456px) {
  .date-widthhh {
    flex-direction: column;
    width: 250px !important;
  }
}

/* .page-banner {
  position: relative;
} */
.banner {
  /* background-image: url(../img/1.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% 10%;
}

.page-banner .overlay {
  position: relative;
  background-image: linear-gradient(rgb(29, 43, 125) 0%, rgb(157, 12, 61) 100%);
  background-blend-mode: overlay;
  background-size: auto;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  opacity: 0.5;
}

/* .page-banner.overlay {
  background-image: linear-gradient(rgb(29, 43, 125) 0%, rgb(157, 12, 61) 100%);
  background-blend-mode: overlay;
  background-size: auto;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  opacity: 0.5;
} */

/* .page-banner .overlay {
  background-image: linear-gradient(rgb(29, 43, 125) 0%, rgb(157, 12, 61) 100%);
} */

.dark-gradientt .overlay {
  background: linear-gradient(178.6deg, #343434 11.8%, #1b1212 83.8%);
  background-blend-mode: overlay;
  background-size: auto;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 10;
  /* Behind content */
  /* opacity: 0.5; */
}

.content-container {
  z-index: 30;
  /* Above overlay */
}

/* .title-texttt {
  color: white !important;
} */
.text-blackkk {
  color: #343434;
}

.bg-blackkk {
  background-color: #343434;
}
.text-white {
  color: white;
}
.text-paraaaaa {
  color: "#707070";
}

.dropdown-bgg {
  background-color: #434343;
}

.button-colorrr {
  color: #707070;
}

.text-event-titlee {
  color: #1b1212;
}

.text-event-titlee:hover {
  color: #7e3af2;
}

.event-title-dark {
  color: white;
}

.event-title-dark:hover {
  color: #7e3af2;
}

.button-bgggg {
  background-color: #f7f7f7;
}

.custom-button {
  color: white;
  background-color: #e91e63 !important;
  /* Button background color */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
}

.nav-widthhh {
  width: 80% !important;
  /* border: 2px solid blue !important; */
}

@media only screen and (max-width: 1280px) {
  .nav-widthhh {
    width: 85% !important;
  }
}

@media only screen and (max-width: 1024px) {
  .nav-widthhh {
    width: 90% !important;
  }
}

@media only screen and (max-width: 768px) {
  .nav-widthhh {
    width: 90% !important;
  }
}

@media only screen and (max-width: 640px) {
  .nav-widthhh {
    width: 89% !important;
  }
}

@media only screen and (max-width: 456px) {
  .nav-widthhh {
    width: 95% !important;
  }
}
@media only screen and (max-width: 456px) {
  .responsive-navvv {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .top-marginn {
    margin-top: 10px !important;
  }
}

.nav-buttonnn {
  border-radius: 25px;
  height: fit-content;
  background-color: rgb(181, 18, 73);
  color: white;
  /* border: 1px solid #7E3AF2; */
}

.nav-buttonnn:hover {
  background-color: #ffff;
  color: #7e3af2;
}

.nav-buttonnn:focus {
  border: 0px;
  outline: none;
}

/* .dark-mode-input input[type="date"]::-webkit-calendar-picker-indicator {
  color: #f3f4f6 !important; 
}

.light-mode-input input[type="date"]::-webkit-calendar-picker-indicator {
  color: #000; 
}

.date-input {
  background-color: transparent;
} */

input[type="date"]::-webkit-calendar-picker-indicator {
  color: #ffff;
}

input[type="date"]::-moz-calendar {
  color: white !important;
}

.gradientttt {
  background: linear-gradient(to bottom, #c084fc, #7e22ce, #581c87);
}

.form-containerrrr {
  border-radius: 16px;
}

.generate-buttonnn {
  width: 100%;
  background: linear-gradient(to right, #c084fc, #7e22ce, #581c87);
  font-size: 18px;
}

.generate-buttonnn:hover {
  background: linear-gradient(to left, #c084fc, #7e22ce, #581c87);
}
.message-texttt {
  font-size: 18px;
}

.btn3:hover {
  background-color: #fff;
  color: #e91e63;
  transition: all 0.5s ease-in-out;
}

.btn,
.btn1,
.btn2 {
  font-size: 15px;
  border-radius: 25px;
  padding: 15px 25px;
  transition: all 0.5s ease-in-out;
  background-color: #e91e63;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  white-space: nowrap;
}
.items-stretch {
  align-items: stretch !important;
}
.bottommmmm {
  bottom: 20px;
  left: auto;
  margin-top: 30px;
}

.heightttt {
  height: "100px";
}
.responsive-width-logo {
  /* width: 230px; */
  height: 85px;
}

@media only screen and (max-width: 767px) {
  .responsive-width-logo {
    width: 170px;
  }
  .btn {
    font-size: 13px;
    padding: 12px 15px;
  }
  .nav-widthhh {
    width: 90% !important;
    display: flex;
  }
}

@media only screen and (max-width: 467px) {
  .btn {
    font-size: 12px;
    padding: 12px 15px;
  }
}

.responsive-widthhh {
  width: 50%;
}
@media only screen and (max-width: 467px) {
  .responsive-width-logo {
    width: 130px;
  }
}

.flex-wrapping {
  flex-wrap: nowrap;
}

@media only screen and (max-width: 1023px) {
  .flex-wrapping {
    flex-wrap: wrap;
  }
}

.bg-darkk {
  background-color: #343434;
}
.text-primaryy {
  color: #223645;
}
